import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const ServicesPage = ({ data, ...props }) => (
  <Layout pathname={props.location.pathname}>
    <SEO title="Our Building Services" />
    <h1>Our Building Services</h1>

    <div className="flex flex-wrap -mx-4">
      <div className="w-full lg:w-1/2 px-4">
        <h2>
          Commercial
        </h2>

        <h3><Link to="/projects/commercial/barwon-heads-dental">Barwon Heads Dental</Link></h3>

        <p>
          <Link to="/projects/commercial/barwon-heads-dental">
            <Img
              fluid={data.barwonImage.childImageSharp.fluid}
              className="my-6 rounded-sm"
            />
          </Link>
        </p>

        <h3><Link to="/projects/commercial/hobsons-bay-dental">Hobsons Bay Dental</Link></h3>

        <p>
          <Link to="/projects/commercial/hobsons-bay-dental">
            <Img
              fluid={data.hobsonsImage.childImageSharp.fluid}
              className="my-6 rounded-sm"
            />
          </Link>
        </p>
      </div>

      <div className="w-full lg:w-1/2 px-4">
        <h2>
          Residential
        </h2>

        <h3><Link to="/projects/residential/eltham">Eltham Renovation</Link></h3>

        <p>
          <Link to="/projects/residential/eltham">
            <Img
              fluid={data.elthamImage.childImageSharp.fluid}
              className="my-6 rounded-sm"
            />
          </Link>
        </p>

        <h3><Link to="/projects/residential/surrey-hills">Surrey Hills Renovation</Link></h3>

        <p>
          <Link to="/projects/residential/surrey-hills">
            <Img
              fluid={data.surreyHillsImage.childImageSharp.fluid}
              className="my-6 rounded-sm"
            />
          </Link>
        </p>

        <h3><Link to="/projects/residential/north-warrandyte">North Warrandyte Renovation</Link></h3>

        <p>
          <Link to="/projects/residential/north-warrandyte">
            <Img
              fluid={data.northWarrandyteImage.childImageSharp.fluid}
              className="my-6 rounded-sm"
            />
          </Link>
        </p>

        <h3><Link to="/projects/residential/warranwood">Warranwood Renovation</Link></h3>

        <p>
          <Link to="/projects/residential/warranwood">
            <Img
              fluid={data.warranwoodImage.childImageSharp.fluid}
              className="my-6 rounded-sm"
            />
          </Link>
        </p>
      </div>
    </div>
  </Layout>
)

export default ServicesPage

export const query = graphql`
  query {
    hobsonsImage: file(relativePath: { eq: "projects/commercial/hobsons-bay-dental/02.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 200, maxWidth: 500, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    barwonImage: file(relativePath: { eq: "projects/commercial/barwon-heads-dental/10.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 200, maxWidth: 500, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    elthamImage: file(relativePath: { eq: "projects/residential/eltham/20190731_BURMPRO_4557-HDR.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 150, maxWidth: 500, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    surreyHillsImage: file(relativePath: { eq: "projects/residential/surrey-hills/20190731_BURMPRO_4439-HDR.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 150, maxWidth: 500, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    northWarrandyteImage: file(relativePath: { eq: "projects/residential/north-warrandyte/20190731_BURMPRO_4610-HDR.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 150, maxWidth: 500, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    warranwoodImage: file(relativePath: { eq: "projects/residential/warranwood/20190731_BURMPRO_4659-HDR.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 150, maxWidth: 500, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
